<template>
  <ModuleForm
    :module-name="$t('moduleForm.poll')"
    :module="poll"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled && poll.type === 'results'"
        type="button"
        :title="$t('pollVlm.button_manage_votes')"
        @click="showVotesModal"
      />
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :disabled="loading"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="poll.id"
        :record-id="poll.id"
        :disabled="loading"
        store-name="pollVlm"
        list-router-path="poll"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/poll')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="loading" on-top />
      <div class="form-top-part">
        <div class="form-top-part__left">
          <SiteSelect
            v-model="poll.site"
            class="mb-3"
            @blur="$v.poll.site.$touch()"
            :error="$v.poll.site.$error"
            :options="sites"
            id="poll_sites"
            :label="$t('pollVlm.server')"
            :disabled="disabled"
            required
          />
          <Input
            v-model="poll.title"
            @blur="$v.poll.title.$touch()"
            :error="$v.poll.title.$error"
            id="poll_title"
            :label="$t('pollVlm.title')"
            :disabled="disabled"
            required
          />
          <div class="two-columns">
            <Datepicker
              v-model="poll.startOfVoting"
              @blur="$v.poll.startOfVoting.$touch()"
              :error="$v.poll.startOfVoting.$error"
              id="poll_startOfVoting"
              :label="`${$t('pollVlm.startOfVoting')} *`"
              :disabled="disabled"
              class="two-columns__column"
            />
            <Datepicker
              v-model="poll.endOfVoting"
              @blur="$v.poll.endOfVoting.$touch()"
              :error="$v.poll.endOfVoting.$error"
              id="poll_endOfVoting"
              :label="`${$t('pollVlm.endOfVoting')} *`"
              :disabled="disabled"
              class="two-columns__column"
            />
          </div>
          <div class="two-columns">
            <Select
              v-model="poll.displayType"
              :options="displayTypes"
              id="poll_displayType"
              :label="$t('pollVlm.displayType')"
              no-empty-value
              :disabled="disabled"
              class="two-columns__column"
            />
            <Select
              v-model="poll.template"
              :options="templates"
              id="poll_template"
              :label="$t('pollVlm.template')"
              no-empty-value
              :disabled="disabled"
              class="two-columns__column"
            />
          </div>
          <Select
            v-model="poll.restrictionType"
            :options="restrictionTypes"
            id="poll_restrictionType"
            :label="$t('pollVlm.restrictionTypes')"
            no-empty-value
            :disabled="disabled"
          />
          <div class="two-columns">
            <SwitchCheckbox
              v-model="poll.loggedInUsersOnly"
              id="poll_loggedInUsersOnly"
              :label="$t('pollVlm.loggedInUsersOnlyLabel')"
              :false-label="$t('pollVlm.loggedInUsersOnlyLabelFalse')"
              :true-label="$t('pollVlm.loggedInUsersOnlyLabelTrue')"
              :disabled="disabled"
              class="two-columns__column"
            />
            <SwitchCheckbox
              v-model="poll.displayResultsBeforeVoting"
              id="poll_displayResultsBeforeVoting"
              :label="$t('pollVlm.displayResultsBeforeVoting')"
              :false-label="$t('pollVlm.loggedInUsersOnlyLabelFalse')"
              :true-label="$t('pollVlm.loggedInUsersOnlyLabelTrue')"
              :disabled="disabled"
              class="two-columns__column"
            />
          </div>
        </div>
        <div class="form-top-part__right">
          <div class="title-text">
            {{ $t('pollVlm.image') }}
          </div>
          <ModuleFormPhotoBox
            :image="poll.imageInfo"
            :disabled="disabled"
            :site="poll.site"
            :media-usage-type="mediaUsageTypes.poll"
            @set-media="setImage"
            @remove-media="removeImage"
          />
          <OutlinedCheckbox
            v-if="poll.type !== 'results'"
            v-model="poll.enabledVoting"
            id="poll_enabledVoting"
            :label="$t('pollVlm.enabledVoting')"
            :disabled="disabled"
          />
          <OutlinedCheckbox
            v-model="poll.hidden"
            id="poll_hidden"
            :label="$t('pollVlm.hidden')"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="main-heading-text">
        {{ $t(`poll.${disabled ? 'results' : 'options'}`) }}
      </div>
      <div v-if="disabled">
        <PollResults :poll="poll" />
      </div>
      <div v-else>
        <Draggable :list="poll.options" handle=".drag-drop-arrows">
          <div
            v-for="(option, index) in poll.options"
            :key="`option-${index}`"
            class="options"
          >
            <div class="four-columns">
              <div class="four-columns__drag-drop align-middle-center drag-drop-arrows">
                <i class="fa fa-arrow-up" />
                <i class="fa fa-arrow-down" />
              </div>
              <div class="four-columns__inputs">
                <Input
                  v-model="option.title"
                  :label="$t('pollVlm.options.answer')"
                  @blur="$v.poll.options.$each[index].title.$touch()"
                  :error="$v.poll.options.$each[index].title.$error"
                  class="options__answer"
                  id="option_answer"
                  disable-form-group
                  required
                />
                <div class="two-columns">
                  <Input
                    v-model="option.votes"
                    :label="$t('pollVlm.options.voteCount')"
                    @blur="$v.poll.options.$each[index].votes.$touch()"
                    :error="$v.poll.options.$each[index].votes.$error"
                    type="number"
                    :min="0"
                    class="options__vote-count two-columns__vote-count"
                    id="option_voteCount"
                    disable-form-group
                    required
                  />
                  <div class="two-columns__media-buttons">
                    <MediaUploadButton
                      :ref="`uploadButton-${index}`"
                      class="buttons-group__button"
                      :button-text="$t('buttons.upload_photo_from_computer')"
                      :site="poll.site"
                      :media-usage-type="mediaUsageTypes.pollOption"
                      @set-media="setImage($event, index)"
                    />
                    <MediaSelectButton
                      class="buttons-group__button"
                      :button-text="$t('buttons.select_photo_from_bank')"
                      :site="poll.site"
                      :media-usage-type="mediaUsageTypes.pollOption"
                      @set-media="setImage($event, index)"
                    />
                  </div>
                </div>
              </div>
              <div class="four-columns__photo-box">
                <div class="title-text">
                  {{ $t('pollVlm.options.image') }}
                </div>
                <ModuleFormPhotoBox
                  :key="`option-photobox-${index}`"
                  :image="option.imageInfo"
                  :disabled="disabled"
                  :select-click="() => openFileDialog(index)"
                  :site="poll.site"
                  :media-usage-type="mediaUsageTypes.pollOption"
                  @set-media="setImage($event, index)"
                  @remove-media="removeImage(index)"
                  box-height="190px"
                  hide-buttons
                />
              </div>
              <div class="four-columns__delete-button align-middle-center">
                <ModuleFormButton
                  v-if="!disabled && poll.options.length > minimumOptions"
                  icon="delete"
                  class="options__delete-button"
                  @click="removeOption(index)"
                />
              </div>
            </div>
          </div>
        </Draggable>
        <button
          v-if="poll.template !== 'yesNo' || poll.options.length < yesNoTemplateMaxOptions"
          class="options__add-button"
          :title="$t('pollVlm.addOption')"
          @click="addOption"
        >
          <PlusIcon class="options__add-button__icon" />
          {{ $t('pollVlm.addOption') }}
        </button>

      </div>
      <div class="separator" />
      <PollVotesModal
        v-if="displayVotesModal"
        @close="closeVotesModal"
        :poll="poll"
      >
      </PollVotesModal>
    </template>
  </ModuleForm>
</template>

<script>
import PollModel from '@/model/PollModelVlm'
import PollOption from '@/model/PollOption'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import { maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import NotifyService from '@/services/NotifyService'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import PollResults from '@/components/poll/PollResults'
import PollVotesModal from '@/components/poll/PollVotesModal'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import SwitchCheckbox from '@/components/form/SwitchCheckbox'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import Draggable from 'vuedraggable'
import { MEDIA_USAGE_TYPE_POLL, MEDIA_USAGE_TYPE_POLL_OPTION } from '@/model/ValueObject/MediaUsageTypes'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import Preloader from '@/components/preloader/Preloader'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'PollNewViewVlm',
  components: {
    SiteSelect,
    OutlinedCheckbox,
    SwitchCheckbox,
    ModuleFormPhotoBox,
    PollVotesModal,
    PollResults,
    PlusIcon,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    Input,
    Select,
    Datepicker,
    MediaSelectButton,
    MediaUploadButton,
    Draggable,
    Preloader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      poll: this._.cloneDeep(PollModel),
      displayVotesModal: false,
      minimumOptions: 2,
      yesNoTemplateMaxOptions: 2,
      mediaUsageTypes: {
        poll: MEDIA_USAGE_TYPE_POLL,
        pollOption: MEDIA_USAGE_TYPE_POLL_OPTION
      }
    }
  },
  computed: {
    displayTypes () {
      return [
        { id: 'numeric', title: this.$t('pollVlm.displayTypesOptions.votes') },
        { id: 'percentage', title: this.$t('pollVlm.displayTypesOptions.percentage') }
      ]
    },
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled || site.id === this.poll.site)
    },
    templates () {
      return [
        { id: 'default', title: this.$t('pollVlm.templateOptions.default') },
        { id: 'yesNo', title: this.$t('pollVlm.templateOptions.yesNo') },
        { id: 'basic', title: this.$t('pollVlm.templateOptions.basic') }
      ]
    },
    restrictionTypes () {
      return [
        { id: 'per5Minutes', title: this.$t('pollVlm.restrictionTypeOptions.fiveMinutes') },
        { id: 'per15Minutes', title: this.$t('pollVlm.restrictionTypeOptions.fifteenMinutes') },
        { id: 'per30Minutes', title: this.$t('pollVlm.restrictionTypeOptions.thirtyMinutes') },
        { id: 'perHour', title: this.$t('pollVlm.restrictionTypeOptions.sixtyMinutes') },
        { id: 'perDay', title: this.$t('pollVlm.restrictionTypeOptions.oneDay') },
        { id: 'perMonth', title: this.$t('pollVlm.restrictionTypeOptions.oneMonth') },
        { id: 'once', title: this.$t('pollVlm.restrictionTypeOptions.onlyOnce') }
      ]
    }
  },
  created () {
    this.poll.type = 'poll'
    for (let i = 0; i < this.minimumOptions; i += 1) {
      this.addOption()
    }
  },
  validations: {
    poll: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(250)
      },
      site: {
        required
      },
      startOfVoting: {
        required
      },
      endOfVoting: {
        required
      },
      options: {
        $each: {
          title: {
            required
          },
          votes: {
            required,
            minValue: minValue(0)
          }
        }
      }
    }
  },
  methods: {
    openFileDialog (index) {
      this.$refs[`uploadButton-${index}`][0].openFileDialog()
    },
    showVotesModal () {
      this.displayVotesModal = true
    },
    closeVotesModal () {
      this.getPoll()
      this.displayVotesModal = false
    },
    getPoll () {
      this.loading = true
      this.$store.dispatch('pollVlm/fetchOne', this.$route.params.id)
        .then(() => {
          this.poll = this.$store.getters['pollVlm/detail']
          this.poll.options.sort((a, b) => a.position - b.position)
          this.loading = false
        })
    },
    async save () {
      this.$v.$touch()
      const formInvalid = this.$v.$invalid
      const hasOptions = this.poll.options?.length >= this.minimumOptions
      const yesNoTemplateMaxOptionsError = this.poll.template === 'yesNo' && this.poll.options?.length > this.yesNoTemplateMaxOptions
      if (formInvalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      }
      if (!hasOptions) {
        NotifyService.setErrorMessage(this.$t('pollVlm.minimumOptionsError'))
      }
      if (yesNoTemplateMaxOptionsError) {
        NotifyService.setErrorMessage(this.$t('pollVlm.yesNoTemplateMaxOptionsError'))
      }
      if (formInvalid || !hasOptions || yesNoTemplateMaxOptionsError) {
        return
      }
      this.poll.options.forEach((option, index) => {
        option.position = index
        option.votes = +option.votes
      })
      this.loading = true
      const action = this.poll.id ? 'update' : 'create'
      const successNotify = this.poll.id ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`pollVlm/${action}`, this.poll)
        .then(() => {
          const error = this.$store.getters['pollVlm/error']
          if (!error) {
            this.poll = this.$store.getters['pollVlm/detail']
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            this.goToEdit()
          } else {
            console.error(error)
            NotifyService.setErrorMessage(error)
          }
        })
        .catch(error => console.error(error))
        .finally(() => { this.loading = false })
    },
    addOption () {
      const newOption = this._.cloneDeep(PollOption)
      this.poll.options.push(newOption)
    },
    removeOption (index) {
      this.poll.options.splice(index, 1)
    },
    setImageOrOptionImage ({ image, optionIndex }) {
      if (optionIndex || optionIndex === 0) {
        this.poll.options[optionIndex].imageInfo = image
        this.poll.options[optionIndex].image = image?.id ?? null
      } else {
        this.poll.imageInfo = image
        this.poll.image = image?.id ?? null
      }
    },
    setImage (medias, optionIndex) {
      this.setImageOrOptionImage({ image: medias[0], optionIndex })
    },
    removeImage (optionIndex) {
      this.setImageOrOptionImage({ image: null, optionIndex })
    },
    goToEdit () {
      this.$router.push(`/poll/${this.poll.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.form-top-part {
  display: flex;
  gap: 2%;
  &__left {
    flex: 0 0 65%;
  }
  &__right {
    flex: 0 0 32%;
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
  &__vote-count {
    flex: 0 0 29%;
    margin-top: 2rem;
  }
  &__media-buttons {
    flex: 0 0 69%;
    display: flex;
    justify-content: flex-end;
    gap: 1%;
    margin-top: auto;
  }
}
.four-columns {
  display: flex;
  gap: 1%;
  &__drag-drop {
    flex: 0 0 4%;
    cursor: pointer;
    i {
      display: block;
    }
  }
  &__inputs {
    flex: 0 0 68%;
    margin-top: 1rem;
  }
  &__photo-box {
    flex: 0 0 20%;
  }
  &__delete-button {
    flex: 0 0 4%;
  }
}
.align-middle-center {
  margin: auto;
  text-align: center;
}
.main-heading-text {
   @include font(400 20px "Roboto");
   margin-top: rem(30px);
   margin-bottom: rem(10px);
 }
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
  margin-bottom: rem(5px);
}
.separator {
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  background: #D1DBE4;
  height: rem(1px);
}
.options {
  background-color: #efefef;
  margin-bottom: rem(10px);
  padding: 0.5rem;
  &__answer {
    flex: 0 0 calc(100% - 45px);
    margin-right: rem(10px);
  }
  &__delete-button {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
  &__add-button {
    @include font(700 13px "Roboto");
    @include padding(7px 14px);
    @include radius(4px);
    width: rem(150px);
    height: rem(30px);
    cursor: pointer;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    @include bp(10) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__icon {
      @include size(rem(14px));
      fill: #FFFFFF;
      float: left;
      margin-right: rem(8px);
    }
  }
}
</style>
